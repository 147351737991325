import * as React from "react";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import usersModel from "../models/UsersModel";


const userStructure = {
    id: null,
    name: null,
    email: null,
    type: null,
    settings: {
        units: 'metric'
    },
    access_token: null,
    access_token_expires_at: null,
    refresh_token: null,
    refresh_token_expires_at: null,
};

const authStructure = {
    user: userStructure,
    login: () => {},
    logout: () => {},
    canShare: false,
    loginPath: "/auth/login"
}

const AuthContext = React.createContext(authStructure);


function AuthProvider({children}) {
    let initialUser = userStructure,
        storedUser = localStorage.getItem('user');

    if (storedUser) {
        initialUser = JSON.parse(storedUser);
    }

    let [user, setUser] = React.useState(initialUser);


    let login = (newUser) => {
        if (newUser && !newUser.settings) {newUser.settings = userStructure.settings;}
        newUser = {...userStructure, ...newUser};
        setUser(newUser);

        localStorage.setItem('user', JSON.stringify(newUser));
        localStorage.setItem('access_token', newUser.access_token);
        localStorage.setItem('access_token_expires_at', newUser.access_token_expires_at);
        localStorage.setItem('refresh_token', newUser.refresh_token);
        localStorage.setItem('refresh_token_expires_at', newUser.refresh_token_expires_at);
    };


    let logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('access_token');
        localStorage.removeItem('access_token_expires_at');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('refresh_token_expires_at');
        setUser(null);
    };


    let value = JSON.parse(JSON.stringify(authStructure));
    value.user = user;
    value.canShare = !!(user && user.settings && user.settings.sharingAllowed);
    value.login = login;
    value.logout = logout;

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}


function useAuth() {
    return React.useContext(AuthContext);
}


function RequireAuth({children}) {
    let auth = useAuth();
    let location = useLocation();

    let sessionInvalid = !auth.user || !auth.user.access_token,
        sessionRecoverable = false;
    if (auth.user.access_token_expires_at && auth.user.access_token_expires_at < Math.floor(Date.now() / 1000)) {
        sessionInvalid = true;
    }

    if (auth.user.refresh_token && auth.user.refresh_token_expires_at && auth.user.refresh_token_expires_at > Math.floor(Date.now() / 1000)) {
        sessionRecoverable = true;
    }

    if (sessionInvalid && !sessionRecoverable) {
        return <Navigate to={auth.loginPath} state={{from: location}} replace/>;
    }

    return (
        <>
            {children}
        </>
    );
}


export {AuthProvider, useAuth, RequireAuth, userStructure};