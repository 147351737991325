import React, {useEffect} from 'react';

import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import SimpleCardContainer from "../ui/SimpleCardContainer";
import {useNotifications} from "../../providers/NotificationsProvider";
import {useApi} from "../../providers/ApiProvider";
import ProjectsModel from "../../models/ProjectsModel";
import ProjectsSearchForm from "./ProjectsSearchForm";
import {Link} from "react-router-dom";
import SimpleModal from "../ui/SimpleModal";
import ProjectShareModal from "./ProjectShareModal";
import ProjectSharesModel from "../../models/ProjectSharesModel";
import {Tooltip} from "react-tooltip";
import {useAuth} from "../../providers/AuthProvider";
import Pagination from "../ui/Pagination";

export default function ProjectsList(props) {
    const breadcrumbs = useBreadcrumbs();
    const notifications = useNotifications();

    const {user, canShare} = useAuth();
    const apiContext = useApi();
    const projectsModel = new ProjectsModel(apiContext.api);
    const projectSharesModel = new ProjectSharesModel(apiContext.api);

    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = React.useState(false);
    const [showCancelConfirmModal, setShowCancelConfirmModal] = React.useState(false);
    const [shareWith, setShareWith] = React.useState(null);
    const [showShareModal, setShowShareModal] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [pagination, setPagination] = React.useState({});
    const [filter, setFilter] = React.useState({
        searchText: null,
        sorting: {field: 'status', direction: 'asc'},
        status: '',
        page: 1
    });
    const [loading, setLoading] = React.useState(true);


    const loadData = () => {
        setLoading(true);

        projectsModel.list(filter, filter.sorting, filter.status, uploadErrorHandler).then((response) => {
            if (response !== false) {
                let pagination = {...response};
                delete pagination.data;

                setData(response.data);
                setPagination(pagination);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const uploadErrorHandler = (response) => {
        if (response.data && response.data[0].error) {
            notifications.notify('Loading error', 'error');
        }
    }


    useEffect(() => {
        breadcrumbs.update({
            title: ''
        });

        document.title = 'My Projects | Geolist';

        let newFilter = {...filter};
        let urlParams = new URLSearchParams(window.location.search);
        let search = urlParams.get('filter');
        if (search) {
            newFilter.searchText = search;
        }

        let status = urlParams.get('status');
        if (status) {
            newFilter.status = status;
        }

        let sort = urlParams.get('sort');
        let direction = urlParams.get('direction');
        if (sort && direction) {
            newFilter.sorting = {field: sort, direction: direction};
        }

        if (!search && !status && !sort && !direction) {
            loadData();
        } else {
            setFilter(newFilter);
        }
    }, []);


    useEffect(() => {
        loadData();
        updateUrl();
    }, [filter]);


    const updateUrl = () => {
        let url = '/p';
        let query = {};
        if (filter.searchText) {
            query.filter = filter.searchText;
        }
        if (filter.status) {
            query.status = filter.status;
        }
        if (filter.sorting.field && filter.sorting.direction) {
            if (filter.sorting.field !== 'status' || filter.sorting.direction !== 'asc') {
                query.sort = filter.sorting.field;
                query.direction = filter.sorting.direction;
            }
        }
        if (Object.keys(query).length) {
            url += '?' + new URLSearchParams(query).toString();
        }
        window.history.pushState({}, '', url);
    }


    const onSearch = (searchText) => {
        let newFilter = {...filter};
        newFilter.searchText = searchText;
        setFilter(newFilter);
    }


    const onStatusToggle = (type) => {
        let newFilter = {...filter};
        newFilter.status = filter.status === type ? null : type;
        setFilter(newFilter);
    }


    const onSort = (e) => {
        let newFilter = {...filter};
        let sortField = e.target.getAttribute('data-sort');
        let sortDirection = 'asc';

        if (e.target.classList.contains('asc')) {
            e.target.classList.remove('asc');
            e.target.classList.add('desc');

            if (e.target.querySelector('i.ti')) {
                e.target.querySelector('i.ti').classList.remove('ti-sort-ascending');
                e.target.querySelector('i.ti').classList.add('ti-sort-descending');
            }

            sortDirection = 'desc';
        } else {
            e.target.classList.remove('desc');
            e.target.classList.add('asc');

            if (e.target.querySelector('i.ti')) {
                e.target.querySelector('i.ti').classList.remove('ti-sort-descending');
                e.target.querySelector('i.ti').classList.add('ti-sort-ascending');
            }
        }

        newFilter.sorting = {field: sortField, direction: sortDirection};
        setFilter(newFilter);
    }


    const onCopyClick = (projectId) => {
        setLoading(true);

        projectsModel.copy(projectId, uploadErrorHandler).then((response) => {
            if (response !== false) {
                notifications.notify('Project copied', 'success');
                loadData();
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const onAccessLevelClick = (projectId) => {
        setLoading(true);

        const newAccessLevel = data.find((item) => item.id === projectId).access_level === 'public' ? 'private' : 'public';
        projectsModel.update(projectId, {access_level: newAccessLevel}, uploadErrorHandler).then((response) => {
            if (response !== false) {
                notifications.notify('Access level updated', 'success');
                loadData();
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const onDeleteClick = (projectId) => {
        setLoading(true);

        projectsModel.delete(projectId, uploadErrorHandler).then((response) => {
            if (response !== false) {
                notifications.notify('Project deleted', 'success');
                setShowDeleteConfirmModal(false);
                loadData();
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const onCancelClick = (projectId) => {
        projectsModel.update(projectId, {status:  '30_canceled'}, uploadErrorHandler).then((response) => {
            if (response !== false) {
                notifications.notify('Project canceled', 'success');
                setShowCancelConfirmModal(false);
            }
        }).finally(() => {
            loadData();
        });
    }


    const onArchiveClick = (project) => {
        projectsModel.update(project.id, {status:  '20_archived'}, uploadErrorHandler).then((response) => {
            if (response !== false) {
                notifications.notify('Project updated', 'success');
            }
        }).finally(() => {
            loadData();
        });
    }

    const onRecoverClick = (project) => {
        projectsModel.update(project.id, {status:  '00_in_progress'}, uploadErrorHandler).then((response) => {
            if (response !== false) {
                notifications.notify('Project updated', 'success');
            }
        }).finally(() => {
            loadData();
        });
    }

    const onShareInit = (project) => {
        setShareWith(project);
        setShowShareModal(true);
    }

    const onShareCancel = () => {
        setShareWith(null);
        setShowShareModal(false);
    }

    const onShareSubmit = (userId, level) => {
        setLoading(true);

        projectSharesModel.create({
            invited_id: userId,
            project_id: shareWith.id,
            level: level
        }, shareSubmitErrorHandler).then((response) => {
            if (response !== false) {
                notifications.notify('Project shared', 'success');
                onShareCancel();
                loadData();
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const shareSubmitErrorHandler = (response) => {
        if (response.data && !response.data.success) {
            let message = 'Error sharing project';
            if (response.data.data) {
                message = response.data.data;
            }
            notifications.notify(message, 'error');
        }
    }


    const onShareRemove = (shareId) => {
        setLoading(true);

        projectSharesModel.delete(shareId, shareSubmitErrorHandler).then((response) => {
            if (response !== false) {
                notifications.notify('Access removed', 'success');
                onShareCancel();
                loadData();
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const getStatusLabel = (status) => {
        switch (status) {
            case '00_in_progress':
                return 'In Progress';
            case '10_processed':
                return 'Processed';
            case '20_archived':
                return 'Archived';
            default:
                return 'Canceled'
        }
    }


    const getStatusClassName = (status) => {
        switch (status) {
            case '00_in_progress':
                return 'text-dark';
            case '10_processed':
                return 'text-success';
            case '20_archived':
                return 'text-muted';
            default:
                return 'text-danger';
        }
    }


    const getAccessLevelLabel = (level) => {
        switch (level) {
            case 'public': return 'Public';
            case 'private': return 'Private';
            default: return 'Not set';
        }
    }


    const userCanEdit = (project) => {
        if (project.user_id === user.id) {
            return true;
        }

        if (project.shares) {
            let share = project.shares.find((share) => share.invited_id === user.id);
            if (share && share.level === 'edit') {
                return true;
            }
        }

        return false;
    }


    return (
        <>
            <SimpleCardContainer
                title={'My Projects'}
                loading={loading}
            >
                <div className={'pt-2 pb-4'}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex justify-content-start gap-3 align-items-center flex-grow-1">
                            <button className="btn btn-light-primary text-primary" onClick={() => onStatusToggle('20_archived')}>
                                {filter.status === '20_archived' ? 'Show All' : 'Show Archived'}
                            </button>

                            <button className="btn btn-light-primary text-primary" onClick={() => onStatusToggle('00_in_progress')}>
                                {filter.status === '00_in_progress' ? 'Show All' : 'In Progress'}
                            </button>

                            <button className="btn btn-light-primary text-primary" onClick={() => onStatusToggle('10_processed')}>
                                {filter.status === '10_processed' ? 'Show All' : 'Processed'}
                            </button>
                        </div>

                        <ProjectsSearchForm onSearch={onSearch}/>
                    </div>
                </div>

                {(data && data.length || loading) ? (
                    <div className="table-responsive rounded-2">
                        <table className="table border customize-table mb-0 align-middle">
                            <thead className="text-dark fs-4">
                            <tr>
                                <th>
                                    <h6 className={'fs-4 fw-semibold mb-0 table-heading-sortable cursor-pointer ' + ((filter.sorting.field === 'title') ? (((filter.sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                        data-sort="title" onClick={onSort}>
                                        Project Title
                                        {(filter.sorting.field === 'title') && (filter.sorting.direction === 'asc') && (
                                            <i className={'ti ti-sort-ascending fs-5'}></i>
                                        )}
                                        {(filter.sorting.field === 'title') && (filter.sorting.direction === 'desc') && (
                                            <i className={'ti ti-sort-descending fs-5'}></i>
                                        )}
                                    </h6>
                                </th>
                                <th>
                                    <h6 className={'fs-4 fw-semibold mb-0 table-heading-sortable cursor-pointer ' + ((filter.sorting.field === 'status') ? (((filter.sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                        data-sort="status" onClick={onSort}>
                                        Status
                                        {(filter.sorting.field === 'status') && (filter.sorting.direction === 'asc') && (
                                            <i className={'ti ti-sort-ascending fs-5'}></i>
                                        )}
                                        {(filter.sorting.field === 'status') && (filter.sorting.direction === 'desc') && (
                                            <i className={'ti ti-sort-descending fs-5'}></i>
                                        )}
                                    </h6>
                                </th>
                                <th>
                                    <h6 className={'fs-4 fw-semibold mb-0 table-heading-sortable cursor-pointer ' + ((filter.sorting.field === 'access_level') ? (((filter.sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                        data-sort="access_level" onClick={onSort}>
                                        Access Level
                                        {(filter.sorting.field === 'access_level') && (filter.sorting.direction === 'asc') && (
                                            <i className={'ti ti-sort-ascending fs-5'}></i>
                                        )}
                                        {(filter.sorting.field === 'access_level') && (filter.sorting.direction === 'desc') && (
                                            <i className={'ti ti-sort-descending fs-5'}></i>
                                        )}
                                    </h6>
                                </th>
                                <th>
                                    <h6 className={'fs-4 fw-semibold mb-0 table-heading-sortable cursor-pointer ' + ((filter.sorting.field === 'locations_total') ? (((filter.sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                        data-sort="locations_total" onClick={onSort}>
                                        Quantity - Available
                                        {(filter.sorting.field === 'locations_total') && (filter.sorting.direction === 'asc') && (
                                            <i className={'ti ti-sort-ascending fs-5'}></i>
                                        )}
                                        {(filter.sorting.field === 'locations_total') && (filter.sorting.direction === 'desc') && (
                                            <i className={'ti ti-sort-descending fs-5'}></i>
                                        )}
                                    </h6>
                                </th>
                                <th>
                                    <h6 className={'fs-4 fw-semibold mb-0 table-heading-sortable cursor-pointer ' + ((filter.sorting.field === 'locations_positive') ? (((filter.sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                        data-sort="locations_positive" onClick={onSort}>
                                        Quantity - Included
                                        {(filter.sorting.field === 'locations_positive') && (filter.sorting.direction === 'asc') && (
                                            <i className={'ti ti-sort-ascending fs-5'}></i>
                                        )}
                                        {(filter.sorting.field === 'locations_positive') && (filter.sorting.direction === 'desc') && (
                                            <i className={'ti ti-sort-descending fs-5'}></i>
                                        )}
                                    </h6>
                                </th>
                                <th>
                                    <h6 className={'fs-4 fw-semibold mb-0 table-heading-sortable cursor-pointer ' + ((filter.sorting.field === 'locations_negative') ? (((filter.sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                        data-sort="locations_negative" onClick={onSort}>
                                        Quantity - Excluded
                                        {(filter.sorting.field === 'locations_negative') && (filter.sorting.direction === 'asc') && (
                                            <i className={'ti ti-sort-ascending fs-5'}></i>
                                        )}
                                        {(filter.sorting.field === 'locations_negative') && (filter.sorting.direction === 'desc') && (
                                            <i className={'ti ti-sort-descending fs-5'}></i>
                                        )}
                                    </h6>
                                </th>
                                <th>
                                    <h6 className={'fs-4 fw-semibold mb-0 table-heading-sortable cursor-pointer ' + ((filter.sorting.field === 'shapes') ? (((filter.sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                        data-sort="shapes" onClick={onSort}>
                                        Shapes
                                        {(filter.sorting.field === 'shapes') && (filter.sorting.direction === 'asc') && (
                                            <i className={'ti ti-sort-ascending fs-5'}></i>
                                        )}
                                        {(filter.sorting.field === 'shapes') && (filter.sorting.direction === 'desc') && (
                                            <i className={'ti ti-sort-descending fs-5'}></i>
                                        )}
                                    </h6>
                                </th>
                                <th style={{width: '200px'}}>
                                    <h6 className={'fs-4 fw-semibold mb-0 table-heading-sortable cursor-pointer ' + ((filter.sorting.field === 'updated_at') ? (((filter.sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                        data-sort="updated_at" onClick={onSort}>
                                        Last Updated
                                        {(filter.sorting.field === 'updated_at') && (filter.sorting.direction === 'asc') && (
                                            <i className={'ti ti-sort-ascending fs-5'}></i>
                                        )}
                                        {(filter.sorting.field === 'updated_at') && (filter.sorting.direction === 'desc') && (
                                            <i className={'ti ti-sort-descending fs-5'}></i>
                                        )}
                                    </h6>
                                </th>
                                <th style={{width: '160px'}}><h6 className="fs-4 fw-semibold mb-0"></h6></th>
                            </tr>
                            </thead>
                            <tbody>
                            {data && data.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div className="d-flex gap-1 flex-column">
                                                <Link to={'/p/' + item.id} className="d-block">{item.title}</Link>
                                                <div className="fs-1 text-muted">#{item.id}</div>
                                                {item.description ? (
                                                    <p>{item.description.split('\n').map((str, index, array) =>
                                                        ((index === array.length - 1) || (!str.length)) ? str : <p key={index} className="mb-1">{str}</p>
                                                    )}</p>
                                                ) : ''}
                                            </div>
                                        </td>
                                        <td className={getStatusClassName(item.status)}>{getStatusLabel(item.status)}</td>
                                        <td>{getAccessLevelLabel(item.access_level)}</td>
                                        <td>{item.locations_total ? item.locations_total : '0'}</td>
                                        <td>{item.locations_positive ? item.locations_positive : '0'}</td>
                                        <td>{item.locations_negative ? item.locations_negative : '0'}</td>
                                        <td>{item.shapes ? item.shapes : '0'}</td>
                                        <td>{(new Date(item.updated_at)).toLocaleString()}</td>
                                        <td>
                                            <div className="d-flex gap-3 justify-content-end">
                                                <button className={'btn btn-circle btn-light text-dark' + (item.status === '30_canceled' ? ' d-none' : '')}
                                                        onClick={() => {
                                                            onCopyClick(item.id)
                                                        }}

                                                        data-tooltip-content="Copy"
                                                        data-tooltip-place="left"
                                                        data-tooltip-id={'control-tooltips-' + item.id}>
                                                    <i className="ti ti-copy fs-4"></i>
                                                </button>
                                                {userCanEdit(item) && (<>
                                                    <button className={'btn btn-circle btn-light text-dark'}
                                                            onClick={() => {
                                                                onAccessLevelClick(item.id)
                                                            }}

                                                            data-tooltip-content={item.access_level === 'public' ? 'Make private' : 'Make public'}
                                                            data-tooltip-place="left"
                                                            data-tooltip-id={'control-tooltips-' + item.id}>
                                                        <i className={'ti ti-' + (item.access_level === 'public' ? 'eye-off' : 'eye') + ' fs-4'}></i>
                                                    </button>
                                                    {canShare ? (
                                                        <button
                                                            className={'btn btn-circle btn-light-primary text-primary' + (item.status === '30_canceled' ? ' d-none' : '')}
                                                            onClick={() => {
                                                                onShareInit(item)
                                                            }}

                                                            data-tooltip-content="Share"
                                                            data-tooltip-place="left"
                                                            data-tooltip-id={'control-tooltips-' + item.id}>
                                                            <i className="ti ti-share fs-4"></i>
                                                        </button>
                                                    ) : ''}
                                                    {item.status !== '20_archived' ? (
                                                        <button
                                                            className={'btn btn-circle btn-light-warning text-warning' + (item.status === '30_canceled' ? ' d-none' : '')}
                                                            onClick={() => {
                                                                onArchiveClick(item)
                                                            }}

                                                            data-tooltip-content="Archive"
                                                            data-tooltip-place="left"
                                                            data-tooltip-id={'control-tooltips-' + item.id}>
                                                            <i className="ti ti-archive fs-4"></i>
                                                        </button>
                                                    ) : (
                                                        <button className={'btn btn-circle btn-light-info text-info'} onClick={() => {
                                                            onRecoverClick(item)
                                                        }}

                                                                data-tooltip-content="Switch to active"
                                                                data-tooltip-place="left"
                                                                data-tooltip-id={'control-tooltips-' + item.id}>
                                                            <i className="ti ti-archive-off fs-4"></i>
                                                        </button>
                                                    )}
                                                    <button
                                                        className={'btn btn-circle d-none btn-light-danger text-danger' + (item.status === '30_canceled' ? ' d-none' : '')}
                                                        title="Cancel the project" onClick={() => {
                                                        setShowCancelConfirmModal(item.id)
                                                    }}

                                                        data-tooltip-content="Cancel"
                                                        data-tooltip-place="left"
                                                        data-tooltip-id={'control-tooltips-' + item.id}>
                                                        <i className="ti ti-x fs-4"></i>
                                                    </button>
                                                    <button className="btn btn-circle btn-light-danger text-danger" title="Permanently remove the project"
                                                            onClick={() => {
                                                                setShowDeleteConfirmModal(item.id)
                                                            }}

                                                            data-tooltip-content="Delete"
                                                            data-tooltip-place="left"
                                                            data-tooltip-id={'control-tooltips-' + item.id}>
                                                        <i className="ti ti-trash fs-4"></i>
                                                    </button>
                                                </>)}


                                                <Tooltip id={'control-tooltips-' + item.id}/>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>

                        <div className="d-flex align-items-center justify-content-center my-4">
                            <Pagination
                                pagination={pagination}
                                onPageChange={(page) => {
                                    let newFilter = {...filter};
                                    newFilter.page = page;
                                    setFilter(newFilter);
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="text-center fs-2 mt-4">
                        No projects found.
                    </div>
                )}
            </SimpleCardContainer>

            <SimpleModal
                visible={showDeleteConfirmModal}
                onClose={() => {
                    setShowDeleteConfirmModal(false)
                }}
                modalContentClassName={'modal-filled bg-light-danger'}
                headerTitle={null}
            >
                <div className="text-center pt-3">
                <i className="ti ti-hexagon-letter-x fs-7 text-danger"></i>
                    <h4 className="mt-2 text-danger">Confirm Delete</h4>
                    <p className="mt-3">Please confirm that you want to delete this project. This is a permanent action, and it can't be undone.</p>

                    <button
                        type="button"
                        className="btn btn-danger my-2"
                        onClick={() => {
                            onDeleteClick(showDeleteConfirmModal)
                        }}
                    >
                        Confirm Delete
                    </button>
                </div>
            </SimpleModal>


            <SimpleModal
                visible={showCancelConfirmModal}
                onClose={() => {setShowCancelConfirmModal(false)}}
                modalContentClassName={'modal-filled bg-light-danger'}
                headerTitle={null}
            >
                <div className="text-center pt-3">
                    <i className="ti ti-hexagon-letter-x fs-7 text-danger"></i>
                    <h4 className="mt-2 text-danger">Confirm Cancel</h4>
                    <p className="mt-3">Please confirm that you want to cancel this project.</p>

                    <button
                        type="button"
                        className="btn btn-danger my-2"
                        onClick={() => {
                            onCancelClick(showCancelConfirmModal)
                        }}
                    >
                        Confirm Cancel
                    </button>
                </div>
            </SimpleModal>


            <ProjectShareModal
                shares={shareWith ? shareWith.shares : []}
                projectId={shareWith ? shareWith.id : ''}
                visible={showShareModal}
                onCancel={onShareCancel}
                onClick={onShareSubmit}
                onRemoveAccess={onShareRemove}
            />
        </>
    );
}