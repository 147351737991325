import React, {useEffect, useState} from 'react';

import {Link, useOutletContext} from "react-router-dom";
import {useApi} from "../../providers/ApiProvider";
import GoogleLogin from "./GoogleLogin";
import {useTranslation} from "react-i18next";
import {useTranslation as useMyTranslation} from "../../providers/TranslationProvider";

const Login = (props) => {
    const {language} = useMyTranslation();
    const { t} = useTranslation();
    const apiContext = useApi();

    const parentContext = useOutletContext();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [error, setError] = useState('');


    const handleSubmit = (e) => {
        e.preventDefault();

        if (email === '' || password === '') {
            setError(t('Please, fill in all fields.'));
            return;
        }

        const credentials = {
            email: email,
            password: password,
            rememberMe: rememberMe
        }

        apiContext.api.authLogin(credentials).then((response) => {
            if (response.data === false) {
                setError(t('Wrong credentials.'));
            } else {
                parentContext.onSuccessfulAuth(response.data);
            }
        });
    }


    useEffect(() => {
        document.title = t('Login')+' | Geolist';
    }, [language]);


    return (
        <>
        <form>
                {error !== '' && <div className="alert alert-danger" role="alert">{error}</div>}

                <div className="mb-4">
                    <label htmlFor="split-login-email" className="form-label">{t('Email address')}</label>
                    <input type="email" className="form-control" id="split-login-email" aria-describedby="emailHelp" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>

                <div className="mb-4">
                    <label htmlFor="split-login-password" className="form-label">{t('Password')}</label>
                    <input type="password" className="form-control" id="split-login-password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>

                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="form-check">
                        <input className="form-check-input primary" type="checkbox" id="split-checkbox" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
                        <label className="form-check-label text-dark" htmlFor="split-checkbox">
                            {t('Remember me')}
                        </label>
                    </div>
                    <Link className="text-primary fw-medium" to="/auth/forgot-password">{t('Forgot Password?')}</Link>
                </div>


                <div className="d-flex align-items-center justify-content-between mb-4">
                    <Link className="text-primary fw-medium" to="https://signup.geolist.co.uk/">Sign Up</Link>
                    <button className="btn btn-primary w-40 py-8 rounded-2" type="submit" name="submit" onClick={handleSubmit}>{t('Sign In')}</button>
                </div>
            </form>
        </>
    );
}

export default Login;
