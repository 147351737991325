import React, {useEffect} from 'react';

import SimpleCardContainer from "../ui/SimpleCardContainer";
import {useApi} from "../../providers/ApiProvider";
import UsersModel from "../../models/UsersModel";
import {useAuth} from "../../providers/AuthProvider";
import {useParams} from "react-router-dom";
import FormSelect from "../layout/ui/FormSelect";
import FormCheckbox from "../layout/ui/FormCheckbox";
import {useNotifications} from "../../providers/NotificationsProvider";

function UserEdit(props) {
    const params = useParams();
    const authContext = useAuth();
    const userData = authContext.user;
    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const usersModel = new UsersModel(apiContext.api);

    const [loading, setLoading] = React.useState(true);
    const [billingPlan, setBillingPlan] = React.useState('client');
    const [name, setName] = React.useState('');
    const [password, setPassword] = React.useState('');
    const types = {
        client: 'Client',
        admin: 'Admin',
    };

    const [userSettingsHome, setUserSettingsHome] = React.useState('');
    const [userSettingsMeasurementUnits, setUserSettingsMeasurementUnits] = React.useState('');
    const [userSettingsSharingAllowed, setUserSettingsSharingAllowed] = React.useState(false);


    useEffect(() => {
        document.title = 'Edit user information | Geolist';

        getData();
    }, []);


    const getData = () => {
        usersModel.view(params.userId).then((response) => {
            if (response !== false) {
                setName(response.name);
                setBillingPlan(response.type);

                if (response.settings) {
                    setUserSettingsHome(response.settings.home.join(','));
                    setUserSettingsMeasurementUnits(response.settings.measurement_units);
                    setUserSettingsSharingAllowed(response.settings.sharingAllowed);
                }
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const onSubmit = (e) => {
        e.preventDefault();

        let homeLocationParsed = userSettingsHome.split(',');
        if (homeLocationParsed.length !== 2) {
            notificationsContext.notify('Home location is not in correct format', 'error');
            return false;
        }
        let homeLocationFormatted = [
            parseFloat(homeLocationParsed[0]),
            parseFloat(homeLocationParsed[1])
        ];

        setLoading(true);

        let dataToSend = {
            'type': billingPlan,
            'name': name,
            'settings': {
                'home': homeLocationFormatted,
                'units': userSettingsMeasurementUnits,
                'sharingAllowed': !!userSettingsSharingAllowed
            }
        }
        if (password.length) {
            dataToSend['password'] = password;
        }
        usersModel.update(params.userId, dataToSend).then((response) => {
            if (response !== false) {
                getData();
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const onImpersonate = (e) => {
        e.preventDefault();

        setLoading(true);
        usersModel.impersonate(params.userId).then((response) => {
            if (response !== false) {
                const formattedResponse = {
                    access_token: response
                }
                let user = usersModel.processAuthResponse(formattedResponse);
                if (user) {
                    authContext.logout();
                    authContext.login(user);
                    window.location.href = '/';
                }
            }
        });
    }


    return (
        <SimpleCardContainer title={'Edit user information'} loading={loading}>
            <form className={'py-2'}>
                <div className={'row'}>
                    <div className={'col-md-6'}>
                        <div className={'row'}>
                            <div className={'col-md-8'}>
                                <div className={'form-group mb-3'}>
                                    <label className="form-label fw-semibold mb-2">{'Full name'}</label>

                                    <input id={'cd-name'} className={'form-control'} value={name} onChange={(e) => {
                                        setName(e.target.value)
                                    }}/>
                                </div>
                            </div>

                            <div className={'col-md-4'}>
                                <div className={'form-group mb-3'}>
                                    <label className="form-label fw-semibold mb-2">{'Role'}</label>

                                    <select id={'cd-button'} className={'form-control form-select'} value={billingPlan} onChange={(e) => {
                                        setBillingPlan(e.target.value)
                                    }}>
                                        {Object.keys(types).map((key) => {
                                            return <option value={key} key={key}>{types[key]}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className={'form-group mb-3'}>
                            <label className="form-label fw-semibold mb-2">{'Password'}</label>

                            <input id={'cd-password'} className={'form-control'} value={password} onChange={(e) => {
                                setPassword(e.target.value)
                            }}/>
                        </div>

                        <h5>User Settings</h5>

                        <div className="row">
                            <div className={'col-md-6'}>
                                <div className={'form-group mb-3'}>
                                    <label className="form-label fw-semibold mb-2">{'Home Location'}</label>

                                    <input id={'cd-user-settings-home'} className={'form-control'} value={userSettingsHome} onChange={(e) => {
                                        setUserSettingsHome(e.target.value)
                                    }}/>
                                    <div className="hint">Coordinates in lat,lng format, e.g. 50.12356,2.144234</div>
                                </div>
                            </div>
                            <div className={'col-md-6'}>
                                <FormSelect
                                    label={'Measurement Units'}
                                    name={'userSettingsMeasurementUnits'}
                                    value={userSettingsMeasurementUnits}
                                    onChange={setUserSettingsMeasurementUnits}
                                    options={[
                                        {value: 'metric', label: 'Metric'},
                                        {value: 'imperial', label: 'Imperial'},
                                        {value: 'disable', label: 'Disable Measurement Units'},
                                    ]}
                                    groupClassName="mb-3"
                                />
                            </div>
                        </div>

                        <FormCheckbox
                            label={'Sharing Allowed'}
                            name={'userSettingsSharingAllowed'}
                            value={userSettingsSharingAllowed}
                            onChange={setUserSettingsSharingAllowed}
                            groupClassName="mb-3"
                        />

                        <button className="btn btn-primary" onClick={onSubmit}>{'Save changes'}</button>
                    </div>
                    <div className={'col-md-6'}>
                        <div className="bordered-tip pb-4 pt-4">
                            <div className="alert alert-warning fs-2">
                                <strong>{'Warning!'}</strong><br/>
                                {'You can operate on user\'s behalf now.'}

                                <button className={'btn btn-danger d-flex align-items-center mt-3'} onClick={onImpersonate}>
                                    <i className={'ti ti-status-change fs-4 me-2'}></i>
                                    {'Impersonate user'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </SimpleCardContainer>
);
}

export default UserEdit;
